/*
    Style top header
*/
.top-header {
    border-bottom: 1px solid #00193e;
    background-color: #1011dd;
}
.top-header > .col-4 > ul > li{
    list-style-type: none;
}
.top-header > .col-4 > ul > li > a {
    color: #fafffe !important;
    padding: 11px 35px !important;
    line-height: 27px !important;
    font-size: 12px;
}

.top-header>div {
    font-size: 11px !important;
    color: #f8fff7 !important;
}

.lang-dropdown {
    float: right !important;
}

.lang-dropdown>a {
    text-decoration: none !important;
    border-right: 0px #ffffff dotted !important;
}
.lang-dropdown>ul>li{
    list-style-type: none;
    z-index: 999999999999999999999;
}
.lang-dropdown>ul>li>a {
    padding: 4px 12px!important;
    text-decoration: none !important;
    background-color: #f3f3f3 !important;
    border-bottom: 1px dotted #ced4da !important;
    color: #000 !important;
    font-size: 12px;
    width: 100% !important;
}


li.lang-dropdown:before {
    content: "\f078";
    color: #fff !important;
    right: 5px;
    top: 7px !important;
    font-size: 9px !important;
}

.flag-logo {
    z-index: 99999;
}

.flag-logo>li>a>img {
    width: 35px;
    padding-right: 5px;
}

/*
    The end of style top header
*/


/*
    Style  header
*/
.header {
    height: 110px;
    background-color: #f3f3f3;
}
.logo-header>img {
    width: 110px;
}

/*
    The end of style header
*/
