
.grid {
    list-style: none;
    margin-left: -21px;
    margin-right: -10px;
}

.gc {
    box-sizing: border-box;
    display: inline-block;
    margin-right: -.25em;
    min-height: 1px;
    padding-left: 11px;
    vertical-align: top;
}

.gc--1-of-3 {
    width: 26.33333%;
}

.gc--2-of-3 {
    width: 73.66666%;
}

.naccs {
    position: relative;
    margin: 10px auto 0;
}

.naccs .menu div {
    padding: 15px 20px 15px 20px;
    margin-bottom: 10px;
    margin-right: 5px;
    /*line-height: 15px;*/
    color: #004DD1;
    background-color: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    position: relative;
    vertical-align: middle;
    font-weight: 700;
    border-radius: 5px;
    transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
    border: 1px solid #c8c9ca;
}

.naccs .menu div span {
    font-weight: 800 !important;
    font-family: Arial, Helvetica, sans-serif !important;
    font-size: 1.5em;
}

.naccs .menu div:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.naccs .menu div span.light {
    height: 10px;
    width: 10px;
    position: absolute;
    top: 24px;
    left: 15px;
    border-radius: 100%;
    transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

.sixD-number-style {
    font-size: 4.427vw !important;
    color: red;
    font-family: Arial, Helvetica, sans-serif !important;
    font-weight: 900;
    /*line-height: 40px;*/
    margin-bottom: 0;
    margin-top: 0;
    margin-right: 21px;
}

.loading-draw-size {
    width: 3vw !important;
    height: 3vw !important;
    margin-right: 1.5vw;
}

.sixD-number-style > span {
    color: red;
    font-family: Arial, Helvetica, sans-serif !important;
    font-weight: 900;
    /*line-height: 40px;*/
    margin-bottom: 0;
    margin-top: 0;
}

.size-number-leap-mix {
    font-size: 25px;
    color: red;
}

.naccs .menu div.active span.light {
    /*background-color: red;*/
    left: 0;
    height: 100%;
    width: 3px;
    top: 0;
    border-radius: 0;
}

.naccs .menu div.active {
    color: white;
    padding: 15px 20px 15px 20px;
    border-radius: 5px;
    background-color: #1011dd;
}


ul.nacc {
    position: relative;
    height: 0px;
    list-style: none;
    margin: 0;
    padding: 0;
    transition: .5s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

ul.nacc li {
    opacity: 0;
    transform: translateX(50px);
    position: absolute;
    list-style: none;
    transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

ul.nacc li.active {
    transition-delay: .3s;
    z-index: 2;
    opacity: 1;
    transform: translateX(0px);
}

ul.nacc li p {
    margin: 0;
    font-size: 1.7em;
}

.ball-number-lo {
    color: #322C20;
    font-size: 25px;
    font-weight: 800;
    font-family: Arial, Helvetica, sans-serif !important;
}

.f-result {
    font-weight: 900 !important;
    font-size: 1.7vw !important;
    white-space: pre-wrap;
    text-align: center;
    font-family: 'Battambang-Bold', Arial, Helvetica, sans-serif !important;
}

.f-result-en {
    font-weight: 900 !important;
    font-size: 1.2vw !important;
    white-space: pre-wrap;
    text-align: center;
    font-family: 'Battambang-Bold', Arial, Helvetica, sans-serif !important;
}

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                     supported by Chrome, Edge, Opera and Firefox */
}

.bg-c-blue {
    background-color: #1011dd;
}

.text-dark-blue {
    color: #1011dd;
}

/*Responsive for Mobile*/
@media only screen
and (min-device-width: 320px)
and (max-device-width: 850px)
and (orientation: portrait) {
    /*.container {*/
    /*    max-width: 100% !important;*/
    /*}*/
    .lo-result {
        font-size: 35px;
    }

    .f-p-20 {
        font-size: 20px;
    }

    .f-result {
        font-size: 25px !important;
    }

    .f-p-26 {
        font-size: 24px;
    }

    .m-p-1 {
        margin-top: 8px !important;
    }

    .l-h-p {
        height: 36px;
    }

    .top-header > .col-4 > ul > li > a {
        font-size: 18px;
    }
}

/*Responsive for tablet and Ipad*/
@media only screen
and (min-device-width: 600px)
and (max-device-width: 1025px)
and (orientation: portrait) {
    /*.container {*/
    /*    max-width: 100% !important;*/
    /*}*/
    .f-t-34 {
        font-size: 34px !important;
    }

    .f-t-70 {
        font-size: 70px !important;
    }

    .f-t-30 {
        font-size: 34px !important;
    }

    .f-t-45 {
        font-size: 45px !important;
    }

    .f-t-50 {
        font-size: 50px !important;
    }

    .f-t-90 {
        font-size: 90px !important;
    }

    .f-t-40 {
        font-size: 40px !important;
    }

    .f-t-28 {
        font-size: 35px !important;
    }

    .m-t-1 {
        line-height: 60px !important;
        height: 100% !important;
    }

    .w-t-100 {
        width: 100% !important;
    }

    .p-t {
        padding-top: 20px;
    }

    .l-h-p {
        height: 36px;
    }

    .social-icon {
        width: 75px;
        height: 75px;
        margin: 0.25rem;
    }

    #winner-banner img {
        width: 100%;
        height: 250px;
    }

    .top-header > .col-4 > ul > li > a {
        font-size: 24px;
    }

    #navmenu li ul li a {
        width: 100% !important;
    }

    .w-t {
        width: 250px !important;
    }

    .font-w-900 {
        font-weight: 900 !important;
        font-size: 26px;
        padding: 0 20px;
    }

    .result-tb {
        font-weight: bold;
        font-size: 40px !important;
    }

    .lo-result {
        font-size: 50px;
    }

    .spacial-result {
        font-size: 60px;
    }

    .card-product .big-result {
        font-size: 80px;
    }

    .sixD-number-style {
        font-size: 120px;
    }

    .f-result {
        font-size: 50px !important;
    }


    .boxBottom {
        width: 100% !important;
        font-weight: 400;
    }

    form div div input::-webkit-input-placeholder {
        font-size: 25px;
        line-height: 3;
    }

    form div div textarea::-webkit-input-placeholder {
        font-size: 25px;
        line-height: 3;
    }
}

.f-18 {
    font-size: 18px;
}

.f-26 {
    font-size: 26px;
}

.f-20 {
    font-size: 20px;
}

.l-h {
    line-height: 30px;
}

.result-tb {
    font-weight: bold;
    font-size: 1.1vw;
}

.font-w-500 {
    font-weight: 500 !important;
}

.font-w-700 {
    font-weight: 700 !important;
}

.font-w-900 {
    font-weight: 900 !important;
}

.w-16 {
    width: 16% !important;
}

.w-14 {
    width: 14% !important;
}

.w-10 {
    width: 10% !important;
}

.f-100 {
    font-size: 3rem;
}

.p-contact {
    padding-top: 90px;
    padding-bottom: 90px;
}

.h-contact {
    font-style: italic;
    display: inline-block;
    margin: 0;
    line-height: 1;
    -webkit-align-self: center;
}

.r-contact {
    font-size: 1rem;
    margin-bottom: .5rem;
}

.tel-contact {
    color: #767676;
    font-size: 1rem;
    font-style: normal;
    line-height: 1.6;
}

.box-contact {
    margin-bottom: 15px;
}

.form-control, .form-control:focus {
    border: 1px solid #e8e8e8;
}

.form-control {
    background-color: #f5f5f5;
    box-shadow: none;
    color: #565656;
    font-family: 'Rubik', sans-serif;
    font-size: 1rem;
    line-height: 1.43;
    min-height: 3.5em;
    padding: 1.07em .5em;
    margin-bottom: 15px;
}

.mbr-form .input-group-btn button[type="submit"] {
    border-radius: 100px !important;
    padding: 1rem 3rem;
}

.btn-primary, .btn-primary:active {
    background-color: #149dcc !important;
    border-color: #149dcc !important;
    color: #ffffff !important;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.display-4 {
    font-family: 'Rubik', sans-serif;
    font-size: 1rem;
}

.display-4 > .mbr-iconfont {
    font-size: 1.6rem;
}

[type="submit"] {
    -webkit-appearance: none;
}

.btn {
    font-weight: 500;
    border-width: 2px;
    font-style: normal;
    letter-spacing: 1px;
    margin: .4rem .8rem;
    white-space: normal;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    padding: 1rem 3rem;
    border-radius: 3px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    word-break: break-word;
}

.google-map {
    /*z-index: 3;*/
    /*position: absolute;*/
    height: inherit !important;
    width: inherit !important;
    padding: 0;
    border-width: 0;
    margin: 0;
    left: 0;
    top: 0;
    touch-action: pan-x pan-y;
}

.gm-style {
    font: 400 11px Roboto, Arial, sans-serif;
    text-decoration: none;
}

.under-line {
    text-decoration: underline;
}

.under-line:hover {
    text-decoration: none;
}
