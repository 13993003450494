.card-media {
    background-color: #f3f3f3;
    padding-top: 2px;
    padding-bottom: 2px;
}

.post-module {
    position: relative;
    z-index: 1;
    display: block;
    background: #FFFFFF;
    height: 385px;
    -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
}
.post-module:hover,
.hover {
    -webkit-box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);
}
.post-module:hover .thumbnail img,
.hover .thumbnail img {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    transform: scale(1.1);
    opacity: .6;
}
.post-module .thumbnail {
    background: #000000;
    height: 385px;
    overflow: hidden;
}
.post-module .thumbnail .date {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
    background: #e74c3c;
    width: 55px;
    height: 55px;
    padding: 12.5px 0;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    color: #FFFFFF;
    font-weight: 700;
    text-align: center;
    -webkti-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.post-module .thumbnail .date .day {
    font-size: 18px;
}
.post-module .thumbnail .date .month {
    font-size: 12px;
    text-transform: uppercase;
}
.post-module .thumbnail img {
    display: block;
    width: 120%;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
}
.post-module .post-content {
    position: absolute;
    bottom: 0;
    background: #FFFFFF;
    width: 100%;
    padding: 16px;
    -webkti-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
    -moz-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
    -ms-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
    -o-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
    transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
}
.post-module .post-content .category {
    position: absolute;
    top: -34px;
    left: 0;
    background: #004ffd;
    padding: 9px 8px;
    color: #FFFFFF;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
}
.post-module .post-content .title {
    margin: 0;
    padding: 0 0 10px;
    color: #333333;
    font-size: 26px;
    font-weight: 700;
}
.post-module .post-content .sub_title {
    margin: 0;
    padding: 0 0 5px;
    color: #0013e0;
    font-size: 20px;
    font-weight: 400;
}
.post-module .post-content .description {
    display: none;
    color: #666666;
    font-size: 14px;
    line-height: 1.8em;
}
.post-module .post-content .post-meta {
    margin: 5px 0 0;
    color: #999999;
}
.post-module .post-content .post-meta .timestamp {
    margin: 0 16px 0 0;
}
.post-module .post-content .post-meta a {
    color: #999999;
    text-decoration: none;
}
.hover .post-content .description {
    display: block !important;
    height: auto !important;
    opacity: 1 !important;
}
.demo-title {
    margin: 0 0 15px;
    color: #666666;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
}
.card-clock {
    padding-left: 0;
    font-size: 0.85vw;
}
.sub_title {
    font-size: 1.4vw;
    font-weight: 600;
}
