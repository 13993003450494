@font-face {
    font-family: 'Battambang-Bold';
    src: url('../fonts/khmer/Battambang-Bold.ttf');
}

@font-face {
    font-family: 'Battambang-Regular';
    src: url('../fonts/khmer/Battambang-Regular.ttf');
}

@font-face {
    font-family: 'KhmerMN-Regular';
    src: url('../fonts/khmer/KhmerMN-01.ttf');
}

@font-face {
    font-family: 'KhmerMN-Bold';
    src: url('../fonts/khmer/KhmerMN-Bold-02.ttf');
}

@font-face {
    font-family: 'Times-New-Roman';
    src: url('../fonts/khmer/Times New Roman 400.ttf');
}

@font-face {
    font-family: "Moul";
    src: url('../fonts/khmer/Moul-Regular.ttf');
}

@font-face {
    font-family: "Khmer OS Kulen";
    src: url("../fonts/khmer/Koulen-Regular.ttf");
}
body {
    font-family: 'Battambang-Regular', Arial, Helvetica, sans-serif !important;

}

p, a {
    font-family: 'Battambang-Regular', Arial, Helvetica, sans-serif !important;
}


h1, h2, h3, h4, h5, h6 {
    font-family: 'Battambang-Bold', Arial, Helvetica, sans-serif !important;
}

#live-report ul > li::before {
    content: "♦";
    font-size: 1.5vw;
    padding-right: 5px;
    color: red;
}


@keyframes blink {
    from {
        color: green;
    }

    to {
        color: white;
    }
}

@-webkit-keyframes blink {
    from {
        color: green;
    }

    to {
        color: white;
    }
}

#left-banner img {
    width: 100%;
}

#hot-promotion img {
    width: 100%;
}

#winner-banner .card-body {
    height: 600px;
    overflow-y: scroll;
}

#winner-banner img {
    width: 100%;
    height: 120px;
}

#winner-banner .custom-card {
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
}


#style-6::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

#style-6::-webkit-scrollbar {
    width: 3px;
    background-color: #F5F5F5;
}

#style-6::-webkit-scrollbar-thumb {
    background-color: #F90;
    background-image: -webkit-linear-gradient(45deg,
    rgba(255, 255, 255, .2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .2) 50%,
    rgba(255, 255, 255, .2) 75%,
    transparent 75%,
    transparent)
}

.lottery-card-header {
    background-color: #1011dd;
    color: white;
}

/*
    style body
*/

.body-content {
    background-color: #f3f3f3;
}

/*
    The end of style body
*/

/*.none-row {*/
/*    --bs-gutter-x: 0rem !important;*/
/*    padding-right: calc(var(--bs-gutter-x) / 0) !important;*/
/*    padding-left: calc(var(--bs-gutter-x) / 0) !important;*/
/*}*/

.card-product {
    /*margin-bottom: 10px;*/
    /*box-shadow: 0 0 20px rgb(0 0 0 / 10%);*/
    box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    cursor: pointer;
    position: relative;
    vertical-align: middle;
    transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
    border: 1px solid #000000;
}

.prod-width {
    width: 100%;
}

.card-border-right {
    border-right: 1px solid #000000;
}

.card-border-bottom {
    border-bottom: 1px solid #000000;
}

.lo-vertical-middle {
    display: table;
    overflow: hidden;
    background-color: #1711DC;
}
.lottery-ACTIVE .lo-vertical-middle,.lottery-WAITING .lo-vertical-middle{
    background: unset !important;
}

.lo-vertical-middle > p {
    display: table-cell;
    vertical-align: middle;
}

.fa-not-found {
    font-size: 99px;
    color: gray;
}

.post-a-vertical-middle {
    display: table;
    overflow: hidden;
}

.post-a-vertical-middle > p {
    display: table-cell;
    vertical-align: middle;
}

.post-vertical-middle {
    display: table;
    overflow: hidden;
}


.post-vertical-middle > p {
    display: table-cell;
    vertical-align: middle;
}

.lottery-ACTIVE {
    animation: pulse 1s infinite;
}

@keyframes pulse {
    0% {
        background-color: #ffffe6;
    }
    30% {
        background-color: #e6ffff;
    }
    60% {
        background-color: #ffe6e6;
    }
    100% {
        background-color: #e6eeff;
    }
}


.animate-not-found {
    animation: not-found 1s infinite;
}

@keyframes not-found {
    0% {
        color: #ffffe6;
    }
    30% {
        color: #e6ffff;
    }
    60% {
        color: #ffe6e6;
    }
    100% {
        color: #e6eeff;
    }
}

/* style blog */

.head-post > td {
    color: red;
}

.head-post-filter {
    line-height: 2;
}


#leap-result .image-black-white, #leap-result .image-loading {
    width: 21px !important;
}

#leap-result .post-spec .image-black-white, #leap-result .post-spec .image-loading {
    width: 35px !important;
}

#leap-result .post-a .image-black-white, #leap-result .post-a .image-loading {
    width: 50px !important;
}

#sixd-result .post-a .image-black-white, #sixd-result .post-a .image-loading {
    width: 50px !important;
}

#leap-result .post-Lo .image-black-white, #leap-result .post-Lo .image-loading {
    width: 19px !important;
}

.big-result-loading .result-home-loading {
    width: 75px;
    color: black;
}

.lo-result-loading .result-home-loading {
    width: 30px;
}


.result-6d-loading .result-home-loading {
    width: 35px;
}

#leap-result .menu div, #sixd-result .menu div, .shift {
    padding: 5px;
    margin-bottom: 0;
    /*box-shadow: 0 0 20px rgb(0 0 0 / 10%);*/
    box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    cursor: pointer;
    position: relative;
    vertical-align: middle;
    font-weight: 700;
    transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
    border: 1px solid #c8c9ca;
}

.tab-pane.fade {
    transition: all 0.3s;
    transform: translateY(1rem);
}

.tab-pane.fade.show {
    transform: translateY(0rem);
}

#leap-result li.nav-item, #sixd-result li.nav-item {
    border: 1px solid #c8c9ca;
    width: 20%;
    transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

#leap-result li.nav-item a.active div, #sixd-result li.nav-item a.active div {
    color: red;
}

#leap-result li.nav-item a div, #sixd-result li.nav-item a div {
    color: black;
}

.shift-result li.nav-item a div, .shift-result li.nav-item a div {
    color: white;
}

.shift-result li.nav-item a.active div, .shift-result li.nav-item a.active div {
    color: red;
}

.shift-result li.nav-item, .shift-result li.nav-item {
    /*border: 1px solid #c8c9ca;*/
    transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

.btnDownload p {
    color: #0a58ca;
    text-align: center;
    font-weight: bold;
}

.btnDownload img {
    width: 200px;
    height: 70px;
    padding-bottom: 20px;
    display: block;
    margin: auto;
}

.A4 {
    width: 297mm;
    height: 210mm;
    padding: 4px;
}

.custom-card {
    padding: 15px 20px 5px 40px;
    margin-bottom: 0;
    /*box-shadow: 0 0 20px rgb(0 0 0 / 10%);*/
    box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    cursor: pointer;
    position: relative;
    vertical-align: middle;
    font-weight: 700;
    transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
    border: 1px solid #000000;
}

#p80 .printer {
    font-size: 35px;
    color: red;
    cursor: pointer;
}

.boxBottom .ball-num:hover, .boxBottom .ball-num:hover, .boxBottom .ball-num-qr:hover, .boxBottom .ball-num-qr:hover {
    background: radial-gradient(circle at 5px 5px, gold, #001);
}

.boxBottom .ball-num {
    width: 1.4vw;
    height: 1.4vw;
    border-radius: 50%;
    background: #757171;
    background: radial-gradient(circle at 5px 5px, #F7F5F5, #31313A);
    text-shadow: none;
    color: #fff;
    text-align: center;
    cursor: pointer;
    margin-left: 0.6vw;
}

.boxBottom .ball-num-qr {
    width: 2vw;
    height: 2vw;
    border-radius: 50%;
    background: #757171;
    background: radial-gradient(circle at 5px 5px, #F7F5F5, #31313A);
    text-shadow: none;
    color: #fff;
    text-align: center;
    cursor: pointer;
    margin-left: 0.6vw;
}

.boxBottom {
    width: 100%;
    font-weight: 400;
    font-family: 'Battambang-Bold', Arial, Helvetica, sans-serif !important;
    align-items: center;
}

.boxBottom .filter-digits {
    float: left;
    padding: 0 0.5vw;
    border-right: 1px solid #9E9E9E;
    font-weight: 400;
    cursor: pointer;
}

.ball-num {
    font-size: 1vw;
    font-weight: 400;
}

.ball-num-qr {
    font-size: 1.3vw;
    font-weight: 400;
}

.filter-balls {
    width: 16vw;
}

.filter-balls-qr {
    width: 20vw;
}

.card-product .big-result {
    color: maroon;
    font-size: 4vw;
    font-weight: 900;
    /*line-height: 2;*/
    font-family: Arial, Helvetica, sans-serif;
}


.live .big-result {
    font-size: 4vw;
    letter-spacing: .75vw;
}


.big-result-label {
    color: maroon;
    font-size: 65px;
    font-weight: 900;
    line-height: 2;
}

.f-result-num {
    font-family: Arial, Helvetica, sans-serif !important;
}

.f-result-num-ba {
    font-family: 'Battambang-Bold', Arial, Helvetica, sans-serif !important;
}

.fw-none {
    font-weight: normal !important;
}

.lo-result {
    font-family: Arial, Helvetica, sans-serif !important;
    font-size: 2.5vw;
    color: #1711DC;
    font-weight: 900;
    margin-right: 4px;
}

.spacial-result {
    font-family: Arial, Helvetica, sans-serif !important;
    font-size: 3vw;
    color: maroon;
    font-weight: 900;
}

.spacial-result-live {
    font-family: Arial, Helvetica, sans-serif !important;
    font-size: 3vw;
    color: maroon;
    font-weight: 900;
    letter-spacing: .75vw;
}

.big-result-loading h4 {
    color: red;
    font-size: 40px;
    font-weight: 700;
}

.print80 {
    width: 80mm;
    padding: 5px;
}

.print80 td {
    padding: 3px;
    text-align: center;
    font-size: 20px;
}

.post {
    font-weight: bold;
}

.print80 .leap {
    font-family: 'Khmer OS Muol Light';
    padding: 4px;
}

.printer {
    font-size: 25px;
    color: red;
    cursor: pointer;
}

#print-all {
    text-decoration: none;
    color: black;
}

#print-all:hover {
    color: black !important;
}

.post-lo {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    transform: rotate(180deg);
}

.middle-banner-1 img {
    width: 100%;
    z-index: 0;
    padding: 1px;
    border-radius: 10px;
}


#loader img {
    width: 90%;
    position: relative;
    top: 7px;
}

#table-result-mid table {
    border-spacing: 0;
}

#table-result-mid td {
    text-align: center;
}

#table-result-mid td {
    padding: 5px;
}

.post-Lo .highlight {
    border-radius: 50%;
    color: white;
    margin: 0 auto !important;
    font-weight: 800;
    background-color: orangered;
}

.post-a .highlight {
    border-radius: 50%;
    color: white;
    margin: 0 auto !important;
    font-weight: 800;
    background-color: red;
}

.post-special .highlight {
    border-radius: 50%;
    color: white;
    margin: 0 auto !important;
    font-weight: unset !important;
    background-color: orange;
}

#leap-result, #sixd-result {
    position: relative;
}

.printAllLeap, #printAll6D {
    cursor: pointer;
    margin-top: 5px;
    font-size: 16px;
}

.A4 .leap80 {
    display: block !important;
}

.A4 .sixD80 {
    display: block !important;
}

.A4 .leap {
    font-family: 'Khmer OS Muol Light';
    padding: 2px;
    color: black;
    font-weight: bold;
}

.A4 td {
    font-size: 15px !important;
    padding: 1px;
    text-align: center;
    font-weight: bold;
    color: black;
}

.print80 {
    width: 80mm;
    padding: 5px;
}

.print58 {
    width: 260px;
}

#print-leap table {
    width: 100% !important;
}

#print-leap td {
    font-weight: bold;
    text-align: center;
    border: 1px solid black;
    font-size: 22px;
    color: black;
}

.f-print-head {
    font-family: "Khmer OS Muol Light", serif;
}

.print80 td {
    padding: 3px;
    text-align: center;
    font-size: 20px;
    color: black;
    font-weight: bold;
}

.post {
    font-weight: bold;
}

.print80 .leap {
    font-family: 'Khmer OS Muol Light';
    padding: 4px;
    color: black;
    font-weight: bold;
}

.printer {
    font-size: 25px;
    color: red;
    cursor: pointer;
}

#print-all {
    text-decoration: none;
    color: black;
    font-weight: bold;
}

#print-all:hover {
    color: black !important;
}

.post-lo {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    transform: rotate(180deg);
}

.post-a .image-black-white, .post-a .image-loading {
    width: 75px !important;
}

.post-Lo .image-black-white, .post-Lo .image-loading {
    width: 35px !important;
}

.post-spec .image-black-white, .post-spec .image-loading {
    width: 45px !important;
}

.text-blue-c {
    color: #1011dd !important;
}

.text-red-c {
    color: red !important;
}

.image img {
    height: 300px;
    width: 600px;
}

.grid-news {
    display: flex;
    padding: 20px;
}

.text {
    padding: 120px 50px;
}

.active1 {
    background: #ff0000;
    color: #fff;
    border-radius: 5px;
}

.contact-follow-us {
    padding-top: 45px;
    padding-bottom: 45px;
}

.hr-result {
    margin: 5px;
    color: inherit;
    background-color: currentColor;
    border: 0;
    opacity: .25;
}

.f-mn {
    font-family: Battambang-Bold !important;
}

.f-tnr {
    font-family: Times-New-Roman sans-serif !important;
}


.nav-header-shift {
    color: blue;
    border-radius: 5px;
    margin: 2px;
}

.nav-tabs .nav-link.active {
    background-color: #1011dd !important;
    color: white !important;
    border-radius: 5px;
}

.header-date-homepage {
    border: #000000 solid 1px;
}

.header-date-result {
    border-bottom: #000000 solid 1px;
}


.header-date-homepage .text-result {
    font-size: 20px;
    color: #1a1a7b;
    font-weight: 700;
}

a {
    color: #1011dc;
}

.home-result-date {
    font-size: 2.34vw;
    color: #1011db;
    line-height: 4vw;
}

.home-result-date-live {
    font-size: 3vw;
    color: #1011db;
    line-height: 5vw;
    padding-top: 15px
}

.home-result-head-hour {
    font-size: 1.197vw;
    color: #1011db;
    line-height: 2vw;
}

.result-nav-hour {
    font-weight: 800 !important;
    font-family: Arial, Helvetica, sans-serif !important;
    line-height: 2.5;
    font-size: 1.6vw;
}

#post-a .loading-draw {
    width: 1.3vw !important;
    height: 1.3vw !important;
    margin-right: 1.2vw;
}
.live #post-a .loading-draw {
    margin-right: 1.5vw !important;
}

#post-spec .loading-draw {
    margin-right: 1vw;
    width: 1.1vw !important;
    height: 1.1vw !important;
}

.live .loading-draw {
    margin-right: 1.15vw !important;
    margin-left: .2vw !important;

}

.print-all {
    padding: 15px 20px 15px 40px;
    margin-bottom: 10px;
    /*box-shadow: 0 0 20px rgb(0 0 0 / 10%);*/
    box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    cursor: pointer;
    position: relative;
    vertical-align: middle;
    font-weight: 700;
    transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
    border: 1px solid #c8c9ca;
}

hr {
    opacity: 1 !important;
}

.blog-card {
    border: 1px solid #000000 !important;
    border-radius: 0;
}

.table {
    border-color: #000000 !important;
}

.span-head {
    display: table;
    margin: 0 auto;
}

#shift-ul {
    margin-bottom: 5px;
    border-bottom: none;
}

#shift-result .nav-link {
    padding: 5px;
}

.lottery-header-img {
    text-align: center;
}

#style-6 a {
    text-decoration: none;
    color: black;
}

#style-6 a:hover {
    color: blue;
}

#product-filter a:hover {
    color: blue;
}

.fw-bold {
    font-weight: 700 !important;
}

.table-mid {
    border: 1px solid black !important;
}

.p-post-a {
    padding: 8px 12px 8px 12px;
}

.m-loading-a {
    margin-right: 25px;
}

.mr-21 {
    margin-right: 21px;
}

.m-spec {
    margin-right: 3px;
}

.f-14 {
    font-size: 14px !important;
}

.f-18 {
    font-size: 18px !important;
}

.f-15 {
    font-size: 15px !important;
}

.f-slider {
    font-size: 30px !important;
}

#calendar .react-calendar__navigation button {
    background-color: blue;
    color: #FFFFFF;
    font-size: 13px !important;
    padding: 0 !important;
}

#calendar .react-calendar__tile {
    line-height: unset;
    padding: 1.2vw 1vw;
}

#calendar .react-calendar__tile:disabled {
    background-color: #FFFFFF;
}

#calendar .react-calendar button {
    font-size: 1vw;
    font-weight: bold;
}

#calendar .react-calendar__navigation {
    margin-bottom: 0;
}

#calendar .react-calendar__month-view__weekdays {
    background-color: #F1F1F1;
    font-size: 0.75vw;
}

#calendar .react-calendar__tile--now {
    background-color: transparent;
    color: #1011dd;
}

#calendar .react-calendar__month-view__days__day--weekend {
    color: red;
}

#calendar .abbr[data-original-title], abbr[title] {
    text-decoration: none;
}

#calendar abbr[title=Sunday], abbr[title=Saturday] {
    color: red !important;
}

#calendar .react-calendar__tile--active {
    border-radius: 50%;
    background: red;
    color: #FFFFFF;
}

#calendar .react-calendar__tile:hover {
    background-color: #b0daff;
    border-radius: 50%;
}

.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease;
}

.carousel-indicators .active {
    opacity: 1;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

#result-show .sixD-number-style {
    margin-right: 7px;
}

#result-show .lo-result {
    margin-right: 0;
}

.link {
    color: white;
}

.link-active {
    color: black;
    background: orange;
}

.lo-loading {
    width: 1vw;
    padding: 2px;
}


.highlight {

    color: #fff !important;
    border-radius: 50%;
    display: inline-block;
    font-weight: unset !important;
}

.bg-green {
    background: #41E241;
    background: radial-gradient(circle at 5px 5px, #41E241, #001)
}

.bg-light-blue {
    background: #f30;
    background: radial-gradient(circle at 5px 5px, #56FDF8, #000)
}

.bg-dark-green {
    background: linear-gradient(to right, #07EB07, #517C51 48%, #35988C 52%, #3EF3ED);
    background: radial-gradient(circle at 5px 5px, gold, #001)
}

.active-digit {
    color: white;
    background: red;
    border-radius: 5px;
}

.exo-menu > li > a {
    font-size: 1vw;
}

.fw-vw {
    font-size: 1.2vw;
}

.filter-result-list {
    width: 12vw;
}


.fw-digit {
    font-size: 1vw;
}

#result-show .nav-tabs .nav-link {
    padding: 15px 20px 15px 20px;
    margin-bottom: 10px;
    margin-right: 5px;
    /* line-height: 15px; */
    /*color: #004DD1;*/
    background-color: #fff;
    /*box-shadow: 0 0 20px rgb(0 0 0 / 10%);*/
    box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    cursor: pointer;
    position: relative;
    vertical-align: middle;
    font-weight: 700;
    border-radius: 5px;
    transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
    border: 1px solid #c8c9ca;
}

#result-show .nav-tabs .nav-link.active {
    background-color: #004DD1;
}

.w-50px {
    width: 50px;
}

.lottery-ACTIVE {
    animation: pulse 1s infinite;
}

.w-65 {
    width: 65%;
}


.live-lo-vertical-middle {
    display: table;
    overflow: hidden;
    height: 300px;
}

.live-lo-vertical-middle > p {
    display: table-cell;
    vertical-align: middle;
}


.live-post-vertical-middle {
    display: table;
    overflow: hidden;
    height: 255px;
}

.live-post-vertical-middle > p {
    display: table-cell;
    vertical-align: middle;
}

.live-post-a-vertical-middle {
    display: table;
    overflow: hidden;
    height: 100px;
}

.live-post-a-vertical-middle > p {
    display: table-cell;
    vertical-align: middle;
}

.live-page {
    font-size: 35px;
    padding-left: 16px;
}

.pt-35 {
    padding-top: 35px;
}

.post-a-live {
    height: 100px;
    margin-top: -15px;
    padding-left: 445px;
}

.shift-label {
    font-size: 35px;
    padding-left: 10px;
}

h5 {
    font-size: 1.2vw !important;
}

.animate-slide-up {
    animation: slide-up 500ms ease-out;
}

@keyframes slide-up {
    0% {
        opacity: 0;
        transform: translateY(2%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.animate-show-result {
    animation: slide-left-right 500ms ease-in-out;
}

@keyframes slide-left-right {
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    50% {
        opacity: 0;
        transform: translateX(50px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.img-waiting {
    width: 60px;
    margin-right: 10px;
}

.img-waiting-A {
    width: 50px;
    margin-right: 10px;
}

.img-waiting-lo {
    width: 20px;
    margin-right: 3px;
}

.img-waiting-post {
    width: 40px;
    margin-right: 3px;
}

.lottery-ACTIVE {
    animation: pulse 1s infinite;
}

@keyframes pulse {
    0% {
        background-color: #ffffe6;
    }
    30% {
        background-color: #e6ffff;
    }
    60% {
        background-color: #ffe6e6;
    }
    100% {
        background-color: #e6eeff;
    }
}

hr {
    border-top: 1px solid black;
}

.lo-loading-live {
    width: 1.3vw;
    height: 1.3vw;
    margin-right: 0.5vw;
}

.loading-draw-live {
    margin-top: -8px;
    width: 20px !important;
    height: 20px !important;
}

.f-result-live {
    font-weight: 900 !important;
    font-size: 2vw !important;
    white-space: pre-wrap;
    text-align: center;
    font-family: 'Battambang-Bold', Arial, Helvetica, sans-serif !important;
}

#about-us input::placeholder, #about-us input, #about-us textarea::placeholder, #about-us textarea, #about-us button {
    font-family: 'Battambang-Regular', Arial, Helvetica, sans-serif !important;
    font-size: 12px;
}

.f-time-roman {
    font-family: "Times-New-Roman", sans-serif;
}

.nav-tabs .nav-link, .nav-tabs .nav-link:hover {
    margin-bottom: -1px;
    background-color: transparent;
    border: 1px solid #c8c9ca;
    border-radius: 0.25rem;
}

.disabled-element {
    pointer-events: none !important;
}

.m-bottom {
    margin-bottom: 0 !important;
}

/*MT Styles*/


.table_result {
    box-shadow: 1px 1px 3px 4px #ccc;
    -webkit-box-shadow: 3px 2px 9px 1px #988b8b;
}

.result_header {
    /*margin-top: 1px;*/
}

.mb-1px {
    margin-bottom: 1px;
}


.result_header .result_date {
    /*height: 50px;*/
    font-size: 1.5vw;
    line-height: 3vw;
    background: #1011dd;
    color: #fefd00;
}

.date_time {
    padding-right: 2vw;
    align-items: center;
}


.top_title {
    width: 10vw;
    font-size: 1.3vw;
    border-top: 1px solid #bdbdbd !important;
    border-bottom: 1px solid #bdbdbd;
    font-weight: 700;
    background-color: #0140c1 !important;
    color: #fefd00;
    font-family: 'Battambang-Bold', Arial, Helvetica, sans-serif !important;
}

.result_1 .result-mt {
    width: 33.33%;
    background: rgb(216 230 236);
}

.result_1 .result_top {
    font-weight: 700;
    font-size: 2.1vw;
    color: red;
    text-align: center;
    background: rgb(216 230 236);
}

#qr_result tbody tr td .result_1 {
    font-size: 3vw !important;
}

#qr_result tbody tr td .result_9, #qr_result tbody tr td .post-8 {
    font-size: 3vw !important;
    padding: 0 !important;
}


.result_2 .result-mt {
    width: 33.33%;
    background: rgb(216 230 236);
}

.result_2 .result_sec {
    font-weight: 700;
    font-size: 28px !important;
    color: blue;
}

.result_sec {
    height: 48px;
    font-size: 32px !important;
    text-align: center;
    font-weight: 700;
}


.mid_content {
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.display_date {
    font-size: 1.2vw;
    line-height: 50px;
}

.result-mt {
    text-align: center;
    font-size: 1.4vw;
    font-weight: 900 !important;
    float: left;
}

.result-mt .result-5 {
    text-align: center;
    font-size: 1.2vw;
    font-weight: 900 !important;
}

.result_3 .result-mt {
    width: 33.33%;
}

.result_5 .result-mt {
    width: 33.33%;
}

.result_6 .result-mt {
    width: 33.33%;
}

.result_4 .result-mt {
    width: 33.33%;
}

.result_7 .result-mt {
    width: 33.33%;
}

.result_8 .result-mt {
    width: 33.33%;
}

.result_9 .result-mt {
    width: 33.33%;
}

.result_9 {
    color: red;
    font-size: 2vw;
    padding: 0.1vw;
    background: rgb(216 230 236);
}

.result_1 .result_top_night {
    font-size: 2vw;
    background: rgb(216 230 236);
}

.result_top_night {
    font-weight: 700;
    font-size: 2.2vw;
    color: red;
    width: 100%;
    float: left;
    text-align: center;
}

.result_2 .result_night {
    width: 100%;
    text-align: center;
    font-size: 1.2vw;
    font-weight: 700;
}

.result_night {
    text-align: center;
    font-size: 1vw;
    font-weight: 700;
    float: left;
}

.result_3 .result_night {
    width: 50%;
    float: left;
    text-align: center;
    font-size: 1vw;
    vertical-align: middle;
    font-weight: 700;
}

.result_5 .result_night {
    width: 50%;
    float: left;
    text-align: center;
    font-size: 1vw;
    vertical-align: middle;
    font-weight: 700;
}

.result_8 .result_night {
    width: 33.33%;
    float: left;
    text-align: center;
    font-size: 1vw;
    vertical-align: middle;
    font-weight: 700;
    color: blue;
    background: rgb(216 230 236);
}

.result_9 .result_night {
    width: 25%;
    vertical-align: middle;
    float: left;
    text-align: center;
    font-size: 1.2vw;
    font-weight: 700;
}

td .hover {
    background-color: #ffc107 !important;
}

.result_top_night:hover {
    background-color: #ffc107 !important;
}

.result_night:hover {
    background-color: #ffc107;
}

.result-mt div:hover {
    background-color: #ffc107;
}

.result_night_1 {
    font-size: 2vw;
    padding: 0.1vw;
    background: rgb(216 230 236);
    text-align: center;
    font-weight: bold;
    color: red;
}

#qr_result tbody tr td .result_night_1, #qr_result tbody tr td .result_night_8 {
    font-size: 3vw !important;
}

.result_night_2 {
    width: 100%;
    text-align: center;
    font-size: 1.5vw;
    padding: 0.1vw;
    font-weight: 700;
}

.result_night_3 {
    width: 50%;
    text-align: center;
    float: left;
    font-size: 1.5vw;
    padding: 0.1vw;
    vertical-align: middle;
    font-weight: 700;
}

.result_night_4 {
    width: 33.33%;
    text-align: center;
    font-size: 1.5vw;
    padding: 0.1vw;
    font-weight: 700;
    float: left;
}

.result_night_5 {
    width: 50%;
    padding: 0.1vw;
    float: left;
    text-align: center;
    font-size: 1.5vw;
    vertical-align: middle;
    font-weight: 700;
}

.result_night_6 {
    width: 33.33%;
    text-align: center;
    font-size: 1.5vw;
    padding: 0.1vw;
    font-weight: 700;
    float: left;
}

.result_night_7 {
    width: 33.33%;
    float: left;
    text-align: center;
    font-size: 1.5vw;
    padding: 0.1vw;
    vertical-align: middle;
    font-weight: 700;
    color: blue;
    background: rgb(216 230 236);
}

.result_night_8 {
    width: 25%;
    vertical-align: middle;
    color: red;
    float: left;
    text-align: center;
    font-size: 2vw;
    padding: 0.1vw;
    font-weight: bold;
    background: rgb(216 230 236);
}

.result_night_9 {
    padding: 0.1vw;
}

.result_night_1:hover,
.result_night_2:hover,
.result_night_3:hover,
.result_night_7:hover,
.result_night_8:hover,
.result_night_9:hover {
    background-color: #ffc107;
}

.result_night_4 div:hover,
.result_night_5 div:hover,
.result_night_6 div:hover {
    background-color: #ffc107;
}

#mt-result {
    font-family: 'Battambang-Bold', Arial, Helvetica, sans-serif !important;
    justify-content: center;
}

#mt-result .table td, .table th {
    padding: unset;
}

#mt-result .table {
    margin-bottom: unset !important;
}

.result-mt .post-0 {
    font-size: 2vw;
}

#qr_result tbody tr td .post-0 {
    font-size: 3vw !important;
}

.result-mt .post-8 {
    font-size: 1.5vw;
    padding: 0.5vw;
}

.result-mt img {
    width: 1vw;
    padding: 0.1vw;
}

.result_night_1 .post-0 {
    font-size: 2vw;
}

.result_night_1 .post-0 img {
    width: 1vw;
    padding: 0.1vw;
}

.result_night_2 .post-1 img,
.result_night_3 .post-2 img,
.result_night_4 .post-3 img,
.result_night_5 .post-4 img,
.result_night_6 .post-5 img,
.result_night_7 .post-6 img,
.result_night_8 .post-7 img {
    width: 1vw;
}

.draw-style-mt {
    background-color: #EBFAD3;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    font-weight: 500 !important;
    border: 1px solid #31d800;
    padding-left: 0.3vw;
    padding-right: 0.3vw;
}

.draw-style-mt-live {
    background-color: #EBFAD3;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    font-weight: 900 !important;
    border: 1px solid #31d800;
    padding-left: 0.3vw;
    padding-right: 0.3vw;
    font-size: 3vw;
    font-family: Arial, Helvetica, sans-serif !important;
}

.result-mt > div {
    min-height: 2vw;
    border: 1px solid rgba(180, 168, 160, 0.5);
    border-collapse: collapse;
}

#result_night .table tr td {
    border: 1px solid rgba(180, 168, 160, 0.5);
    border-collapse: collapse;
}

#result_night .table tr td div {
    min-height: 45px;
}

#mt-result .table td, .table th {
    border-top: unset;
}

.sub-header td {
    font-size: 1.2vw;
    border: 1px solid rgba(180, 168, 160, 0.5) !important;
}

.sub-header .result-mt:hover {
    background-color: #ffc107;
}

.marquee {
    font-size: 1vw !important;
}

.live-padding {
    padding-right: 1vw;
    padding-left: 1vw;
}

.text-yellow {
    color: #fefd00;
}

.bg-post-mc {
    background-color: rgb(216 230 236);
    border-color: rgba(183, 180, 180, 0.42) !important;
}

.result-mc {
    font-size: 3.5vw;
    font-family: 'Battambang-Bold', Arial, Helvetica, sans-serif !important;
}

.spacial-result-mc {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 3.8vw;
    color: maroon;
    font-weight: 900;
}

.fs-qr-b-num-vn {
    font-size: 4vw !important;
}

.fs-qr-b-num {
    font-size: 5.5vw !important;
}

.fs-qr-num {
    font-size: 4.5vw !important;
}

.fs-qr-s-num {
    font-size: 4vw !important;
}

.fs-qr-lo-num {
    font-size: 3vw !important;
}

.fs-qr-num-vnb {
    font-size: 3.5vw !important;
}

.fs-qr-num-vn {
    font-size: 2.5vw !important;
}

.fs-qr-23 {
    font-size: 2.3vw !important;
}

.result-mc img {
    margin: 0.1vw;
    width: 1vw;
    height: 1vw;
}

.f-version {
    font-size: 0.6vw !important;
}

.text-version {
    color: #9E9E9E;
}

.post-vn {
    color: white;
    font-weight: bold;
    text-align: center;
    vertical-align: middle !important;
    padding: 0 !important;
}

.num-vn {
    font-family: 'Battambang-Bold', Arial, Helvetica, sans-serif !important;
    text-align: center;
    vertical-align: middle;
    padding: 0 !important;
}

.text-post-A, .text-post-night-A, .text-post-B {
    color: #00C2FF !important;
}

.text-post-C, .text-post-night-B, .text-post-D {
    color: #0066FF !important;
}

.text-post-F, .text-post-night-C, .text-post-I {
    color: #00C852 !important;
}

.text-post-N, .text-post-night-D, .text-post-K {
    color: #ACD700;
}

.text-post-P, .text-post-O {
    color: #8d69cc;
}

.text-post-Z {
    color: #e76d6d;
}

.bg-post-A, .bg-post-night-A, .bg-post-B {
    background-color: #00C2FF !important;
}

.bg-post-C, .bg-post-night-B, .bg-post-D {
    background-color: #0066FF;
}

.bg-post-F, .bg-post-night-C, .bg-post-I {
    background-color: #00C852;
}

.bg-post-N, .bg-post-night-D, .bg-post-K {
    background-color: #ACD700;
}

.bg-post-P, .bg-post-O {
    background-color: #8d69cc;
}

.bg-post-Z {
    background-color: #e76d6d;
}

.border-td {
    border: 1px solid black;
}

.bg-blue-dark {
    background-color: #1711DC !important;
}

.p-num-vn {
    padding: 0.06vw 0 !important;
}

.live .post-live {
    width: 3vw !important;
    text-align: center;
}

.mr-ep {
    margin-right: 1vw !important;
}
.result-box-2d {
    height: 6vw;
}

.result-box-2d img{
    margin-right: .55vw !important;
    margin-left: .55vw !important;
}

.result-box-3d{
    height: 5vw ;
}
.result-box-3d img{
    margin-right: .2vw !important;
    margin-left: .2vw !important;
}

.result-box-3d div{
    width: 6.5vw;
}

.result-box-2d div{
    width: 12.5vw;
}

.num-space {
    letter-spacing: .8vw !important;
    padding-left: .4vw;
    padding-right: .4vw;
    font-family: Arial, Helvetica, sans-serif !important;
    font-size: 3vw;
    font-weight: bold;
}
.live .lo-result{
    line-height: 3vw;
    letter-spacing: .4vw;
    width: 13vw !important;
    /*display: flex;*/
    /*justify-content: center;*/
    margin-right: 0 !important;
}
.live .draw-style{
    letter-spacing: normal !important;
}
.td-number-vn{
    height:6vw  !important;
    vertical-align: middle !important;
    letter-spacing: .2vw;
}


.ml-vw{
    margin-left: -2vw!important;
}
.w-1-vw{
    width: 1vw!important;
    margin: 1.2vw;
}

.text-post-sc{
    color: #1711DC;
}

.p-lr-vw{
    padding-left: 10vw;
    padding-right: 10vw;
}

.post-code-sc{
    font-size: 3vw;
    color: yellow;
    font-family: Moul, sans-serif;
}

.number-sc{
    font-size: 3vw;
    font-weight: 900;
    color: #1711DC;
    font-family: "Arial Black", sans-serif;
    letter-spacing: 1vw;
}

.rounded-sc{
    border-radius: 10px;
}

.post-rounded-sc{
    border-radius: 10px 0 0 10px;
}
.img-waiting-post-sc {
    width: 3.3vw;
    margin-right: .1vw;
}

.sc-img{
    padding: 10px;
}

.sc-img img{
    width: 100%;
}

.bg-sc-result{
    background: linear-gradient(#7BFFFF, #0008DD);
}

.social-icons-sc img{
    width: 4vw;
    margin-left: 0.5vw;
    margin-right: 0.5vw;
}
.bg-sc{
    background-color: #f1f1f1;
}

.f-2rem{
    font-size: 2rem;
    font-weight: bold;
    font-family: "Khmer OS Kulen", sans-serif;
}