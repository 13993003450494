*,
:after,
:before {
    box-sizing: border-box
}
nav ul{
    padding: 0.8vw;
    margin: 0;
    display: flex;
}
nav ul li{
    list-style-type: none;
    position: relative;
    z-index: 99;
}

a:hover, a:active, a:focus{
    color: #1011dd;
    text-decoration:none;
}

.head-menu {
    background-color: #ff0000;
}

.exo-menu > li > a{
    padding: 0 1.5vw;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    border-right: 1px #ffffff dotted;
}

.exo-menu > li > a:hover,
.exo-menu > li > .active,
.exo-menu > li > ul >li .active,
.exo-menu > li > ul > li > a:hover{
    color:#ffff08!important;
}

li.drop-down{
    position:relative;
}
li.drop-down:before {
    content: "\f078";
    color: #fff;
    /*font-family: FontAwesome;*/
    font-style: normal;
    display: inline;
    position: absolute;
    right: 15px;
    top:2px;
    font-size: 12px;
}

.icon-drop{
    font-size: 40px;
    vertical-align: middle;
    width: 30px;
    height: 20px;
}

li.drop-down>ul{
    left: 0;
    min-width: 100%;

}
.drop-down-ul{
    position: absolute;
    left: 0;
    display:none;
}

li.drop-down>ul>li>a{
    color: #fff;
    display: block;
    padding: 10px 18px;
    font-size: 0.8vw;
    text-decoration: none;
    background-color: #ff0000;
    border-bottom: 1px dotted #ffffff;
    width: 175px;
    text-align: center;
    top: 0;
}

/*hover*/

.drop-down-ul:hover,
li.drop-down>a:hover +.drop-down-ul{
    display:block;
}

.menu-toggle{
    color: #fff;
    float: right;
    line-height: 50px;
    font-size: 24px;
    cursor: pointer;
    display: none;
}

.nav-tabs {
    border-bottom: 0px solid #dee2e6 !important;
}

