.draw-style {
    width: 1.8vw;
    height: 2.8vw;
    line-height: 2.5vw;
    background-color: #EBFAD3;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    font-family: Arial, Helvetica, sans-serif !important;
    font-weight: 900 !important;
    border: 1px solid #31d800;
}

.draw-style-a {
    font-size: 3.38vw;
    font-family: Arial, Helvetica, sans-serif !important;
    font-weight: 900;
    width: 3vw;
    height: 5vw;
    background-color: #EBFAD3;
    border-radius: 50%;
    display: inline-block;
    line-height: 4.6vw;
    text-align: center;
    white-space: nowrap;
    border: 1px solid #31d800;
}
.live .draw-style-a {
    width: 2.5vw;
    margin-right: .5vw;
}
.draw-spacial-result {
    font-family: Arial, Helvetica, sans-serif !important;
    font-weight: 900;
    width: 2.7vw;
    height: 4vw;
    background-color: #EBFAD3;
    border-radius: 50%;
    display: inline-block;
    line-height: 3.7vw;
    text-align: center;
    white-space: nowrap;
    border: 1px solid #31d800;
}

.live .draw-spacial-result {
    width: 2vw;
    margin-right: .4vw;
}

.draw-style-6d {
    width: 4.2vw;
    height: 6.5vw;
    background-color: #EBFAD3;
    border-radius: 50%;
    display: inline-block;
    line-height: 6vw;
    text-align: center;
    white-space: nowrap;
    border: 1px solid #31d800;
}

