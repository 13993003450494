.carousel-inner{
    height: 100%;
}
.carousel-item p h5{
    color: white;
}
.carousel-item{
    height: 100%;
}
.carousel-item img{
    height: 100%;
}
.carousel-control-prev::after{
    color: white!important;
}

