
#p-result {
    border: black 1px solid;
}

.result {
    width: 30px;
    height: 30px;
    text-align: center;
    font: bold 16px/32px arial, sans-serif;
    letter-spacing: -1px;
    margin: auto;
    top: 0;
    text-shadow: #333 0 2px 2px;
    border-radius: 75px;
    box-shadow: inset #6d34dc 0 0 24px;
    zoom: 1;
}

#p-result {
    text-align: center;
    padding: 10px;
}

#p-result .row {
    padding: 10px;
}

#print-button {
    color: red;
    font-size: 25px;
}

.header-date-result {
    margin: 0 auto;
    text-align: center;
    font-weight: 700;
    font-size: 1.5em;
}

.head-result-date {
    font-weight: 700;
    font-size: 1.6vw;
    font-family: 'Battambang-Bold', Arial, Helvetica, sans-serif !important;
}

.mb-result {
    margin-bottom: 2em !important;
}

.p-result-vn {
    padding: 0.5vh 0 !important;
}

.p-result-head-vn {
    padding: 0.4vh 0 !important;
}

.p-result-draw-vn {
    padding: 0.3vh 0.3vw !important;
}

.fs-result-vn {
    font-size: 2.4vw !important;
}

.fs-result-vn-1 {
    font-size: 1.5vw !important;
}

.fs-result-footer {
    font-size: 1.5vw !important;
}

.fs-result-draw {
    font-size: 2vw !important;
}

.mh-day {
    min-height: 3vh !important;
}